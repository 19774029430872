import React from "react";
import parse from "html-react-parser";

export default function Brief({ brief }) {
  return (
    <section id="brief">
      <div id="brief-container">
        <div className="brief-pic">
          <img
            src="https://res.cloudinary.com/dhzmockpa/image/upload/f_auto,q_auto/v1/Personal%20Portfolio/Personal/profile-pic"
            alt="profile-pic"
            style={{ height: "100%" }}
          />
        </div>
        <div className="brief-text">
          <div className="brief-pre">Hello👋, I'm</div>
          <div className="brief-name">
            Pratham <span>Jaiswal</span>
          </div>
          <div className="brief-description">{parse(brief)}</div>
        </div>
      </div>
    </section>
  );
}
